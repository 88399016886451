<template>
  <li :class="['breadcrumb-item', { 'active': active }]">
    <component :is="tag" v-bind="$attrs"><slot/></component>
  </li>
</template>

<script>
export default {
  name: 'BreadcrumbNavigationItem',
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag () {
      return Object.keys(this.$attrs).includes('href') ? 'a' : 'span'
    }
  }
}
</script>

<style lang="scss">
$breadcrumbnavigation-item-padding: $breadcrumb-item-padding !default;
$breadcrumbnavigation-item-font-size: $breadcrumb-font-size !default;
$breadcrumbnavigation-item-color: $breadcrumb-divider-color !default;
$breadcrumbnavigation-item-hover-color: $breadcrumb-active-color !default;
$breadcrumbnavigation-item-active-color: $breadcrumb-active-color !default;

.breadcrumbnavigation {
  .breadcrumb {
    .breadcrumb-item {
      display: flex;
      flex-wrap: nowrap;
      padding-left: $breadcrumbnavigation-item-padding;
      font-size: $breadcrumbnavigation-item-font-size;
      color: $breadcrumbnavigation-item-color;

      a {
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        a {
          color: $breadcrumbnavigation-item-hover-color;
        }
      }

      &.active {
        color: $breadcrumbnavigation-item-active-color;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
