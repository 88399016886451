<template>
  <nav class="breadcrumbnavigation" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <slot/>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'BreadcrumbNavigation'
}
</script>

<style lang="scss">
$breadcrumbnavigation-outer-margin: $component-gap 0 !default;
$breadcrumbnavigation-outer-bg: $white !default;

$breadcrumbnavigation-padding-y: $breadcrumb-padding-y !default;
$breadcrumbnavigation-padding-x: $component-padding-x !default;
$breadcrumbnavigation-bg: $breadcrumb-bg !default;
$breadcrumbnavigation-border-radius: $leaf-border-radius-lg !default;

.breadcrumbnavigation {
  position: relative;
  margin: $breadcrumbnavigation-outer-margin;
  background-color: $breadcrumbnavigation-outer-bg;

  .breadcrumb {
    @include make-leaf($breadcrumbnavigation-border-radius, 'left');
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    padding: $breadcrumbnavigation-padding-y $breadcrumbnavigation-padding-x;
    background-color: $breadcrumbnavigation-bg;

    &:after {
      display: block;
      content: '';
      flex: 0 0 $breadcrumbnavigation-padding-x;
    }
  }

  #app > main > div > &:first-child {
    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50vw;
      background-color: inherit;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}
</style>
